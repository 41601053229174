<template>
  <div class="content-filtes">
    <div class="content-filter-general-search">
      <v-text-field v-model="sSearch" label="Buscador de nombre o clave..." placeholder="Ingrese nombre o clave..."
        class="global-text-field" color="var(--primary-color-border-input)" background-color="var(--primary-color-menu)"
        persistent-placeholder outlined height="50px">
        <template v-slot:append>
          <v-icon color="#7C8088" size="17px"> mdi-magnify </v-icon>
        </template>
      </v-text-field>
    </div>
    <v-spacer></v-spacer>
    <div class="content-btn-reset-price">
      <v-btn v-show="bAdminProvider" class="global-btn-neutral-with-hover"
        @click="setDialogActionConfirmGlobal(sNameDetail)">
        <v-icon class="icon-reset" v-if="isMobile">mdi-cached</v-icon>
        <span v-else> Restablecer precio{{ aRawMaterialsResetPrice.length !== 0 ? ` (${aRawMaterialsResetPrice.length})`
            : "(Todos)"
        }}</span>
      </v-btn>
    </div>
    <dialog-action-confirm-global @setDialogActionConfirmGlobal="setDialogActionConfirmGlobal"
      :bDialogActionConfirm="bDialogActionConfirm" :oDialogConfirm="oDialogConfirm" />
  </div>
</template>

<script>
export default {
  props: {
    bAdminProvider: Boolean,
  },
  data() {
    return {
      sStatus: null,
      itemStatus: [
        { text: "Seleccionar estado...", value: null, disabled: true },
        { text: "Creada", value: 1 },
        { text: "MP Identificada", value: 2 },
        { text: "Peso asignado", value: 3 },
        { text: "Pagada", value: 4 },
        { text: "Cancelada", value: 5 },
      ],
      sSearch: "",
      dates: [],
      menu: false,
      isMobile: false,
      bDialogActionConfirm: false,
      oDialogConfirm: {
        active: true,
        textTitleActionConfirm: "",
        textDescriptionActionConfirm: "",
        textQuestionActionConfirm: "",
        returnToViewActionConfirm: false,
        apiActionConfirm: "",
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 599) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    save(date) {
      this.$refs.menu.save(date);
      let fecha_inicial = this.dates[0];
      let fecha_final = this.dates[1];

      if (this.dates.length > 1) {
        if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
          let lastDate = this.dates[1];
          this.dates = [];
          this.dates[0] = lastDate;
        } else {
          this.$emit("setDate", {
            tStart: fecha_inicial,
            tEnd: fecha_final,
          });
        }
      }
    },
    setDialogActionConfirmGlobal(AccountName) {
      this.oDialogConfirm = {
        active: true,
        textTitleActionConfirm: "Restablecer precio",
        textDescriptionActionConfirm:
          "Estás a punto de restablecer el precio base de todas las materias primas del proveedor. Estas acción es irreversible <br/>¿Seguro que deseas realizar la acción? ",
        textQuestionActionConfirm: `Acepto la responsabilidad y deseo proseguir con el restablecimiento de precios base de : ${AccountName} `,
        returnToViewActionConfirm: false,
        apiActionConfirm: `${URI}/api/sp/v1/vendors/${this.$route.params.id}/materials`,
        iTypeAction: this.getTypeActionConfirmResetPrice(), //1= Post , 2= put , 3= patch , 4=delete , 5 = get
        oParams: this.getParamsConfirmResetPrice(),
      };
      this.bDialogActionConfirm = !this.bDialogActionConfirm;
    },
    getTypeActionConfirmResetPrice() {
      if (this.aRawMaterialsResetPrice.length > 0) {
        return 3;
      } else {
        return 4;
      }
    },
    getParamsConfirmResetPrice() {
      if (this.aRawMaterialsResetPrice.length > 0) {
        return { aRawMaterialIds: this.aRawMaterialsResetPrice }
      } else {
        return {}
      }
    }
  },
  computed: {
    aRawMaterialsResetPrice() {
      return this.$store.state.aRawMaterialsResetPrice
    },
    sTabProviderOrPerson() {
      return this.$store.state.sTabPosition;
    },
    sNameDetail() {
      return this.$store.state.sNameDetail;
    },
    aRawMaterialsResetPrice() {
      return this.$store.state.aRawMaterialsResetPrice
    },
  },
  watch: {
    sSearch() {
      this.$emit("setSearch", this.sSearch);
    },
  },
};
</script>

<style scoped>
.content-filtes {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.content-filter-general-search {
  width: 300px;
}

.content-btn-reset-price {
  width: 200px;
  margin-left: 15px;
}

.icon-reset {
  transform: rotate(0) !important;
  transition: 0.8s !important;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-filtes {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
  }

  .content-filter-general-search {
    width: 100%;
    /* margin-bottom: 10px; */
  }

  .content-btn-reset-price {
    width: 70px;
    margin-left: 15px;
    /* margin-bottom: 10px; */
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>